import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const LandingPage = () => {
  const [professionalMode, setProfessionalMode] = useState(false);
  
  // Background style based on theme
  const backgroundStyle = professionalMode 
    ? { backgroundColor: '#f8f9fa' }
    : { 
        backgroundImage: 'radial-gradient(circle, #00FFFF 2px, transparent 2px)',
        backgroundSize: '24px 24px',
        backgroundColor: '#e0f7fa' 
      };
  
  return (
    <div className={`min-h-screen relative ${professionalMode ? 'bg-gray-50' : 'bg-teal-100'}`} style={backgroundStyle}>
      <Helmet>
        <title>VibeCheck: Discover and Quantify Qualitative Differences in LLMs</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/favicon.ico`} />
        <link href="https://fonts.googleapis.com/css2?family=VT323&family=Press+Start+2P&family=Inter:wght@400;500;600;700&display=swap" rel="stylesheet" />
      </Helmet>
      
      {/* Navigation Header */}
      <header className={`${professionalMode ? 'bg-white shadow-md' : 'bg-yellow-300 shadow-lg border-b-4 border-t-4 border-black'} py-4 sticky top-0 z-50`}>
        <div className="container mx-auto px-4">
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
            <div className="flex items-center justify-between">
              <h1 className={`text-3xl font-bold ${professionalMode ? 'text-gray-800' : 'text-black'}`} 
                  style={professionalMode ? { fontFamily: "'Inter', sans-serif" } : { fontFamily: "'Press Start 2P', cursive" }}>
                VibeCheck
              </h1>
              
              {/* Theme Toggle */}
              <div className="flex items-center md:hidden">
                <button 
                  onClick={() => setProfessionalMode(!professionalMode)}
                  className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
                    professionalMode 
                      ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200' 
                      : 'bg-purple-400 text-white hover:bg-purple-500 border border-black'
                  }`}
                >
                  {professionalMode ? '90s Vibe' : 'Academic Vibe'}
                </button>
              </div>
            </div>
            
            <nav className="flex items-center justify-between mt-4 md:mt-0">
              <ul className="flex flex-wrap gap-6">
                <li>
                  <Link to="/visualizer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`}>Vibe Visualizer</Link>
                </li>
                <li>
                  <a href="https://arxiv.org/abs/2410.12851" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">Paper</a>
                </li>
                <li>
                  <a href="https://github.com/lisadunlap/VibeCheck" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">GitHub</a>
                </li>
                <li>
                  <a href="https://huggingface.co/datasets/lmarena-ai/Llama-3-70b-battles" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-black hover:text-orange-500'} transition-colors font-bold hover:scale-105 inline-block transform`} target="_blank" rel="noopener noreferrer">Dataset</a>
                </li>
              </ul>
              
              {/* Theme Toggle (Desktop) */}
              <div className="hidden md:block ml-6">
                <button 
                  onClick={() => setProfessionalMode(!professionalMode)}
                  className={`px-3 py-1 rounded-md text-sm font-medium transition-colors ${
                    professionalMode 
                      ? 'bg-indigo-100 text-indigo-700 hover:bg-indigo-200' 
                      : 'bg-purple-400 text-white hover:bg-purple-500 border border-black'
                  }`}
                >
                  {professionalMode ? '90s Vibe' : 'Academic Vibe'}
                </button>
              </div>
            </nav>
          </div>
        </div>
      </header>
      
      {/* Blog Post Content */}
      <main className="container mx-auto px-4 py-8 max-w-4xl">
        {/* Title and Authors */}
        <div className="mb-8 text-center relative">
          {!professionalMode && (
            <>
              <div className="absolute -top-6 -left-6 w-24 h-24 bg-lime-400 rounded-full opacity-50"></div>
              <div className="absolute -bottom-6 -right-6 w-32 h-32 bg-orange-400 rounded-full opacity-50"></div>
            </>
          )}
          <h1 className={`text-3xl md:text-4xl font-bold mb-6 relative z-10 ${professionalMode ? 'text-gray-800' : ''}`} 
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF00FF, -1px -1px 0 #00FFFF' }}>
            VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models
          </h1>
          <div className="flex flex-wrap justify-center gap-3 text-lg relative z-10">
            <a href="https://www.lisabdunlap.com/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Lisa Dunlap</a>
            <span>•</span>
            <a href="https://www.linkedin.com/in/krishna-mandal/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Krishna Mandal</a>
            <span>•</span>
            <a href="https://darrellgroup.github.io/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Trevor Darrell</a>
            <span>•</span>
            <a href="https://jsteinhardt.stat.berkeley.edu/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Jacob Steinhardt</a>
            <span>•</span>
            <a href="https://joeygonzalez.com/" target="_blank" rel="noopener noreferrer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-green-600 hover:text-orange-500'} font-bold hover:scale-105 inline-block transform transition-all`}>Joseph E Gonzalez</a>
          </div>
        </div>
        
        {/* Key Findings */}
        <section className={`mb-6 ${professionalMode ? 'bg-white' : 'bg-white'} p-6 ${professionalMode ? 'rounded-lg shadow' : 'rounded-lg shadow-md border-4 border-black'}`} 
                 style={professionalMode ? {} : { backgroundColor: '#FDFD96' }}>
          <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black flex items-center'}`} 
              style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF00FF' }}>
            Key Findings
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <div className={`${professionalMode ? 'bg-gray-50' : 'bg-cyan-300'} ${professionalMode ? 'rounded-lg' : 'rounded-none'} p-5 ${professionalMode ? 'shadow' : 'shadow-md border-2 border-black'} hover:transform hover:scale-105 transition-transform duration-300`}>
              <h3 className="font-semibold mb-2 text-black">Vibe Discovery</h3>
              <p>VibeCheck automatically discovers meaningful qualitative differences between LLMs that align with human intuition.</p>
            </div>
            <div className={`${professionalMode ? 'bg-gray-50' : 'bg-orange-300'} ${professionalMode ? 'rounded-lg' : 'rounded-none'} p-5 ${professionalMode ? 'shadow' : 'shadow-md border-2 border-black'} hover:transform hover:scale-105 transition-transform duration-300`}>
              <h3 className="font-semibold mb-2 text-black">Cross-Task Analysis</h3>
              <p>Different models show distinct vibes across summarization, math problem-solving, and image captioning tasks.</p>
            </div>
            <div className={`${professionalMode ? 'bg-gray-50' : 'bg-lime-300'} ${professionalMode ? 'rounded-lg' : 'rounded-none'} p-5 ${professionalMode ? 'shadow' : 'shadow-md border-2 border-black'} hover:transform hover:scale-105 transition-transform duration-300`}>
              <h3 className="font-semibold mb-2 text-black">Interactive Exploration</h3>
              <p>Explore model vibes and see example outputs with our <Link to="/visualizer" className={`${professionalMode ? 'text-indigo-600 hover:text-indigo-800' : 'text-blue-600 font-bold underline hover:text-orange-500'}`}>interactive visualizer</Link>.</p>
            </div>
          </div>
        </section>
        
        {/* Abstract */}
        <section className={`mb-6 ${professionalMode ? 'bg-white rounded-lg shadow' : 'bg-white shadow-md border-4 border-black'}`} style={professionalMode ? {} : { backgroundColor: '#98FB98' }}>
          {!professionalMode && (
            <>
              {/* Removed the circles as requested */}
            </>
          )}
          <div className="p-6">
            <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`} 
                style={professionalMode ? {} : { textShadow: '1px 1px 0 #00FFFF' }}>
              Abstract
            </h2>
            <div className="prose max-w-none text-center">
              <p>
                Large language models (LLMs) often exhibit subtle yet distinctive characteristics in their outputs that users intuitively recognize, but struggle to quantify. These "vibes" -- such as tone, formatting, or writing style -- influence user preferences, yet traditional evaluations focus primarily on the singular axis of correctness. We introduce VibeCheck, a system for automatically comparing a pair of LLMs by discovering identifying traits of a model (vibes) that are well-defined, differentiating, and user-aligned.
              </p>
              <p>
                VibeCheck iteratively discovers vibes from model outputs and then utilizes a panel of LLM judges to quantitatively measure the utility of each vibe. We validate that the vibes generated by VibeCheck align with those found in human discovery and run VibeCheck on pairwise preference data from real-world user conversations with Llama-3-70b vs GPT-4. VibeCheck reveals that Llama has a friendly, funny, and somewhat controversial vibe. These vibes predict model identity with 80% accuracy and human preference with 61% accuracy.
              </p>
              <p>
                Lastly, we run VibeCheck on a variety of models and tasks including summarization, math, and captioning to provide insight into differences in model behavior. VibeCheck discovers vibes like Command X prefers to add concrete intros and conclusions when summarizing in comparison to TNGL, Llama-405b often overexplains its thought process on math problems compared to GPT-4o, and GPT-4 prefers to focus on the mood and emotions of the scene when captioning compared to Gemini-1.5-Flash.
              </p>
            </div>
          </div>
        </section>
        
        {/* Method */}
        <section className={`mb-6 ${professionalMode ? 'bg-white rounded-lg shadow' : 'bg-white shadow-md border-4 border-black'}`} style={professionalMode ? {} : { backgroundColor: '#FFFFFF' }}>
          <div className="p-6">
            <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`} 
                style={professionalMode ? {} : { textShadow: '1px 1px 0 #FF6347' }}>
              Method
            </h2>
            <div className="flex items-center justify-center mb-4 relative">
              {!professionalMode && (
                <div className="absolute -top-20 -left-3 w-20 h-20 bg-yellow-300 rounded-full opacity-30 animate-pulse"></div>
              )}
              <img
                src={`${process.env.PUBLIC_URL}/method.jpeg`}
                alt="VibeCheck Method Diagram"
                className="w-full h-auto shadow-md"
                onError={(e) => {
                  // Fallback to a direct path if the PUBLIC_URL version fails
                  e.target.onerror = null;
                  e.target.src = "/method.jpeg";
                  // If your repo is not at the root domain, use this instead:
                  // e.target.src = "/your-repo-name/method.jpeg";
                }}
              />
            </div>
            <div className="prose max-w-none text-center">
              <p>
                The VibeCheck system operates in three main phases: (1) vibe discovery, where we automatically identify candidate vibe descriptions that differentiate LLM outputs; (2) vibe validation, where we filter and refine vibes to ensure they are well-defined, distinctive, and user-aligned; and (3) vibe analysis, where we use the discovered vibes to analyze model outputs and predict user preferences.
              </p>
            </div>
          </div>
        </section>
        
        {/* Citation */}
        <section className={`mb-6 ${professionalMode ? 'bg-white rounded-lg shadow' : 'bg-white shadow-md border-4 border-black'}`} style={professionalMode ? {} : { backgroundColor: '#FF9999' }}>
          <div className="p-6">
            <h2 className={`text-2xl font-bold mb-4 ${professionalMode ? 'text-gray-800' : 'text-black'}`} 
                style={professionalMode ? {} : { textShadow: '1px 1px 0 #00FF00' }}>
              Citation
            </h2>
            <div className={`${professionalMode ? 'bg-gray-100' : 'bg-yellow-200'} p-3 font-mono text-sm overflow-x-auto ${professionalMode ? 'border rounded' : 'border-2 border-dashed border-black'}`}>
              <pre>
{`@article{dunlap2023vibecheck,
  title={VibeCheck: Discover and Quantify Qualitative Differences in Large Language Models},
  author={Dunlap, Lisa and Mandal, Krishna and Darrell, Trevor and Steinhardt, Jacob and Gonzalez, Joseph E},
  journal={International Conference on Learning Representations},
  year={2023},
  url={https://arxiv.org/abs/2309.12112}
}`}
              </pre>
            </div>
          </div>
        </section>
      </main>
      
      {/* Footer */}
      <footer className={professionalMode ? "bg-gray-800 text-white py-6" : "bg-black text-white py-6 border-t-4 border-yellow-400"}>
        <div className="container mx-auto px-4 text-center">
          {professionalMode ? (
            <p>&copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved.</p>
          ) : (
            <div className="scrolling-text-container">
              <div className="scrolling-text">
                &copy; {new Date().getFullYear()} VibeCheck Team. All rights reserved. ☆彡 ★彡 ☆彡 ★彡
              </div>
            </div>
          )}
        </div>
      </footer>
    </div>
  );
};

export default LandingPage; 